import styled from "styled-components";
import FraudeConsorcioBackground from "../../../asserts/images/consorcios/fraude-background.png";

export const Background = styled.div(({ theme }) => ({
  background: `url(${FraudeConsorcioBackground})`,
  backgroundSize: "cover",
  height: "90vh",
  minHeight: "500px",
  display: "flex",
  flexDirection: "column",
  backgroundPositionX: "73%",
  [theme.breakpoint.lg()]: {
    backgroundPositionX: "center",
    height: "80vh",
  },
}));

export const Container = styled.div(({ theme }) => ({
  width: "90%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  paddingLeft: "0",
  marginTop: "24px",
  maxWidth: "1140px",
  marginLeft: "auto",
  marginRight: "auto",
  height: "90vh",
  gap: "28px",
  justifyContent: "center",
  [theme.breakpoint.lg()]: {
    height: "80vh",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    gap: "60px",
    marginBottom: "8%",
  },
}));

export const TitleText = styled.h1(({ theme }) => ({
  fontSize: "52px",
  color: "#fff",
  [theme.breakpoint.lg()]: {
    fontSize: "80px",
  },
}));

export const SubtitleText = styled.h1(({ theme }) => ({
  fontSize: "28px",
  color: "#fff",
  [theme.breakpoint.lg()]: {
    fontSize: "36px",
  },
}));

export const Box = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  color: "#454E4F",
  background: "#F7F7F7",
  padding: "21px 16px",
  margin: "auto",
  marginBottom: "-100px",
  [theme.breakpoint.lg()]: {
    padding: "21px",
  },
  [theme.breakpoint.xl()]: {
    padding: "21px 0",
  },
}));
