import "./style.css";
import React from "react";
import AreaRoute from "../../components/shared/routes";
import { HomePage, PosContemplacao, Assembleias } from "./pages";
import { useSegmentRoute } from "../../hooks";
import { envs } from "../../config";
import Security from "./pages/Security";
import Sortudao from "./pages/Sortudao";

export const Consorcios = () => {
  const rotasConsorcios = useSegmentRoute([
    {
      component: HomePage,
      default: true,
    },
    {
      path: "/contemplacao",
      component: PosContemplacao,
      pageTitle: "Contemplação",
      pageProps: {
        updateCrumb: {
          label: "Consórcio",
          href: `${window.location.origin}/consorcio`,
        },
      },
    },
    {
      path: "/resultado-de-assembleias",
      component: Assembleias,
      pageTitle: "Assembleias",
      pageProps: {
        updateCrumb: {
          label: "Consórcio",
          href: `${window.location.origin}/consorcio`,
        },
      },
    },
    {
      path: "/seguranca",
      component: Security,
      pageTitle: "Segurança",
      pageProps: {
        updateCrumb: {
          label: "Consórcio",
          href: `${window.location.origin}/consorcio`,
        },
      },
    },
    {
      path: "/sortudao",
      component: Sortudao,
      pageTitle: "Sortudão",
      pageProps: {},
    },
  ]);

  return (
    <AreaRoute
      {...rotasConsorcios}
      title="Consórcio"
      chatId={envs.consorcioChatId}
    />
  );
};

export default Consorcios;
