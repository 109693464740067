import amplieRenoveSuaFrota from "./amplie-renove-frotas.svg";
import entendaConsorcios from "./entenda-concorcios-ilustracao.svg";
import reduzaTaxaConsorcio from "./reduza-taxa-impostos-ilustracao.svg";
import semJurosConsorcio from "./sem-juros-ilustracao.svg";
import useSeuFgtsConsorcio from "./use-seu-fgts-ilustracao.svg";
import entregaBemContemplacao from "./entrega-bem-contemplacao-ilustracao.svg";
import valorCredito from "./valor-credito-ilustracao.svg";
import valorDinheiro from "./valor-dinheiro-ilustracao.svg";
import ilustracaoProximasAssembleias from "./proximas-assembleias-ilustracao.svg";
import comunicadoContemplacao from "./comunicado-contemplacao-ilustracao.svg";
import cotasContempladasNaoEncontrada from "./cotas-contempladas-nao-encontrada-ilustracao.svg";
import cotasContempladasEncontrada from "./cotas-contempladas-encontrada-ilustracao.svg";
import fraudeBackground from "./fraude-background.png";
import celularFraude from "./celular-fraude.png";
import cuidadoFraude from "./cuidado-fraude.png";
import familiaComCasaAmarela from "./familia-com-casa-amarela.png";
import mulherComLapis from "./mulher-com-lapis.png";
import pessoaFraude from "./pessoa-fraude.png";
import pessoasPositivas from "./pessoas-positivas.png";
import pessoasTransferindo from "./pessoas-transferindo.png";
import bannerAniversarioSeguridade from "./banner-aniversario-seguridade-desktop.png";
import bannerAniversarioSeguridadeMobile from "./banner-aniversario-seguridade-mobile.png";
import bannerAniversarioSeguridadeProrrogado from "./banner_site_aniversário_seguridade.png";
import bannerAniversarioSeguridadeProrrogadoMobile from "./banner_mobile_aniversário_seguridade.png";
import bannerLPSortudao from "./banner-lp-sortudao.png";
import bannerLPSortudaoMobile from "./banner-lp-sortudao-mobile.png";
import backgroundSortudao from "../background-sortudao.png";
import iconAquisicaoConsorcio from "../icon-aquisicao-consorcio.svg";
import iconImovel from "../icon-imovel.svg";
import iconNumeroSorte from "../icon-numero-sorte.svg";
import iconPremiado from "../icon-premiado.svg";
import iconPremio from "../icon-premio.svg";
import iconSejaSortudao from "../icon-seja-sortudao.svg";
import iconVeiculosLeve from "../icon-veiculos_leve.svg";
import iconVeiculosPesado from "../icon-veiculos_pesado.svg";
import bannerSortudao from "./banner-sortudao.png";
import bannerSortudaoMobile from "./banner-sortudao-mobile.png";
import bannerCDG from "./banner-cdg.png";
import bannerCDGMobile from "./banner-cdg-mobile.png";
import bannerContratacaoSegura from "./banner-contratacao-segura.png";
import bannerContratacaoSeguraMobile from "./banner-contratacao-segura-mobile.png";
import bannerAniversario3Anos from "./banner-aniversario-3anos.png";
import bannerAniversario3AnosMobile from "./banner-aniversario-3anos-mobile.png";
import bannerLibras from "./banner-libras.png";
import bannerLibrasMobile from "./banner-libras-mobile.png";
import bannerAniversario3AnosProrrogacao from "./banner-aniversario-3anos-prorrogacao.png";
import bannerAniversario3AnosProrrogacaoMobile from "./banner-aniversario-3anos-prorrogacao-mobile.png";

export default {
  amplieRenoveSuaFrota,
  entendaConsorcios,
  reduzaTaxaConsorcio,
  semJurosConsorcio,
  useSeuFgtsConsorcio,
  entregaBemContemplacao,
  valorCredito,
  valorDinheiro,
  ilustracaoProximasAssembleias,
  comunicadoContemplacao,
  cotasContempladasNaoEncontrada,
  cotasContempladasEncontrada,
  fraudeBackground,
  celularFraude,
  cuidadoFraude,
  familiaComCasaAmarela,
  mulherComLapis,
  pessoaFraude,
  pessoasPositivas,
  pessoasTransferindo,
  bannerAniversarioSeguridade,
  bannerAniversarioSeguridadeMobile,
  bannerAniversarioSeguridadeProrrogado,
  bannerAniversarioSeguridadeProrrogadoMobile,
  bannerLPSortudao,
  bannerLPSortudaoMobile,
  backgroundSortudao,
  iconAquisicaoConsorcio,
  iconImovel,
  iconNumeroSorte,
  iconPremiado,
  iconPremio,
  iconSejaSortudao,
  iconVeiculosLeve,
  iconVeiculosPesado,
  bannerSortudao,
  bannerSortudaoMobile,
  bannerCDG,
  bannerCDGMobile,
  bannerContratacaoSegura,
  bannerContratacaoSeguraMobile,
  bannerAniversario3Anos,
  bannerAniversario3AnosMobile,
  bannerLibras,
  bannerLibrasMobile,
  bannerAniversario3AnosProrrogacao,
  bannerAniversario3AnosProrrogacaoMobile
};
