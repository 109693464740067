/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { LINKS } from "../../../constants";
import { Page, Section } from "../../../components/shared/pages";
import imagensConsorcio from "../../../asserts/images/consorcios";
import { ConsorcioSortudaoFAQ } from "../sections/ConsorcioSortudaoFAQ";
import { ParticipeAgora } from "../sections/ParticipeAgora";
import IconRoullete from "../../../asserts/components/RoulleteIcon";
import IconPrize from "../../../asserts/components/PrizeIcon";
import IconHouse from "../../../asserts/components/HouseIcon";
import IconLightVehicle from "../../../asserts/components/LightVehicleIcon";
import IconHeavyVehicle from "../../../asserts/components/HeavyVehicleIcon";

// import {ReactComponent as rouletteIcon} from "../../../asserts/images/icon-seja-sortudao.svg";

const Sortudao = (props) => {
  const iconStyle = {
    color: "blue",
  };

  const regulamentoSortudao = () => {
    window.open(LINKS.linkRegulamentoAcaoPremiada, "_blank");
  };

  const linkAutoCompra = () => {
    window.open(LINKS.autoCompraConsorcioProdutos, "_blank");
  };

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isLargeScreen ? (
        <a href={LINKS.autoCompraConsorcio} target="_blank" rel="noreferrer">
          <img
            src={imagensConsorcio.bannerLPSortudao}
            alt="bannerLPSortudao"
            style={{ objectFit: "contain", width: "100%" }}
          />
        </a>
      ) : (
        <a href={LINKS.autoCompraConsorcio} target="_blank" rel="noreferrer">
          <img
            src={imagensConsorcio.bannerLPSortudaoMobile}
            alt="bannerLPSortudao"
            style={{ objectFit: "contain", width: "100%" }}
            href={LINKS.autoCompraConsorcio}
          />
        </a>
      )}

      <Page
        id="page-home"
        {...props}
        images={imagensConsorcio}
        className="background-sortudao"
      >
        <Section
          as={ParticipeAgora}
          title="Participe agora"
          className="cc-faq-saiba-mais background-transparent font-color-white headers-size img titleSection"
          id="saiba-mais-consorcio-sortudao"
          sectionLayout={null}
          sectionName="saiba mais consorcio sortudao"
        />

        <p className="text-center orange-button-margin">
          <Button className="orange-button" onClick={regulamentoSortudao}>
            CONFIRA O REGULAMENTO
          </Button>
        </p>

        <div className="info-premio">
          <div className="title">
            <h1>Premiação</h1>
          </div>

          <div className="icon-left">
            <IconRoullete />
          </div>

          <div className="desc-left details display-flex-column">
            <p>O sorteio será no dia </p>
            <p>26/10/2024</p>
          </div>

          <div className="icon-right">
            <IconPrize />
          </div>

          <div className="desc-right details display-flex-column">
            <p style={{ fontSize: "24px" }}>Prêmio de </p>
            <p style={{ fontSize: "32px" }}>R$ 100 mil</p>
          </div>

          <div className="left-info more-info">
            <p>
              O número da sorte do consorciado será sorteado pela Loteria
              Federal.
            </p>
          </div>

          <div className="right-info more-info">
            <p>
              O vencedor do Sortudão será avisado pela CAIXA Consórcio que
              recebeu o prêmio de R$ 100 mil.
            </p>
          </div>

          <div className="line">
            <hr className="white-horizontal-line" />
          </div>

          <p className="luck">Boa Sorte!</p>
        </div>

        <div className="choose-consortium">
          <p className="title-sortudao">
            {" "}
            Escolha qual consórcio você deseja adquirir
          </p>
        </div>

        <div className="options-box">
          <div className="card-options-box">
            <h3>Imobiliário</h3>
            <IconHouse style={iconStyle} />
            <p className="descriptionCard">
              Imóveis residenciais ou comerciais, usados ou na planta, rural ou
              urbanos e terrenos
            </p>
            <Button className="blue-button" onClick={linkAutoCompra}>
              CONTRATAR
            </Button>
          </div>

          <div className="card-options-box">
            <h3>Veículos Leves</h3>
            <IconLightVehicle style={iconStyle} />
            <p className="descriptionCard">
              Carro ou moto, novo ou usado com até 8 anos de uso
            </p>
            <Button className="blue-button" onClick={linkAutoCompra}>
              CONTRATAR
            </Button>
          </div>

          <div className="card-options-box">
            <h3>Veículos Pesados</h3>
            <IconHeavyVehicle />
            <p className="descriptionCard">
              Caminhão, ônibus, trator, máquina ou equipamentos agrícolas
            </p>
            <Button className="blue-button" onClick={linkAutoCompra}>
              CONTRATAR
            </Button>
          </div>
        </div>

        <Section
          as={ConsorcioSortudaoFAQ}
          title="Perguntas frequentes"
          className="cc-faq-saiba-mais titleSection"
          id="saiba-mais-consorcio-sortudao"
          sectionLayout={null}
          sectionName="saiba mais consorcio sortudao"
        />
      </Page>
    </>
  );
};

export default Sortudao;
