import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { LINKS } from "@constants";
import { Link } from "../shared/actions";
import "./style.css";
import { applyKey } from "../../utils";
import { envs } from "../../config";

const SeguridadeFooter = () => {
  const listLink =
    envs.scopedSeguridade === "true"
      ? [
          {
            label: "A Caixa Seguridade",
            href: LINKS.linkQuemSomos,
            ignoreBlank: true,
          },
          { label: "Sobre a CAIXA", href: LINKS.aCaixa },
          {
            label: "Aviso de Privacidade",
            href: LINKS.politicaDePrivacidade,
            ignoreBlank: true,
          },
          { label: "CAIXA Notícias", href: LINKS.caixaNoticias },
          { label: "Blog CAIXA Consórcio", href: LINKS.linkBlogConsorcio },
          {
            label: "Relatório de Transparência e Igualdade Salarial",
            href: LINKS.relatorioTransparenciaSalarial,
          },
          {
            label: "Segurança",
            href: LINKS.seguranca,
          },
          {
            label: "Educação Financeira",
            href: LINKS.linkEducacaoFinanceira,
          },
        ]
      : [
          {
            label: "Caixa Consórcio",
            href: envs.homePortal,
            ignoreBlank: true,
          },
          { label: "Sobre a CAIXA", href: LINKS.aCaixa },
          {
            label: "Relação com Investidores",
            href: LINKS.relacaoInvestidores,
          },
        ];

  return (
    <>
      <div className="menu-overlay menu-conosco">
        <Container className="py-2 px-4 py-md-4" fluid>
          <Row>
            <Col xs={12}>
              <Row>
                <Col className="pl-0" md={12}>
                  <Nav className="flex-column flex-md-row">
                    {applyKey(listLink, (objLink) => {
                      return (
                        <Nav.Link className="pr-5" as={Link} {...objLink} />
                      );
                    })}
                  </Nav>
                </Col>
              </Row>
              <hr className="cvp-bg-color-neutral-5" />
            </Col>
            <Col xs={12}>
              <Row className="flex-wrap-reverse text-body-sm">
                <Col className="text-white" md={10}>
                  <p className="adress">
                    Caixa Consórcio - CNPJ {envs.cnpj}
                    <br />
                    Alameda Xingu, 350, 11º Andar,
                    <br />
                    Barueri/SP, CEP: 06455-030
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

SeguridadeFooter.propTypes = {};

export default SeguridadeFooter;
