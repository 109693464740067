/* eslint-disable max-len */
import React from "react";

function IconPrize() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 400 400"
      version="1"
      viewBox="100 50 150 300"
      xmlSpace="preserve"
      width="200"
      height="200"
    >
      <path
        d="M240.45 186.77c-9.09-10.77-18.82-17.29-21.47-18.96 4.55-8.17 14.65-27.48 13.85-36.29-.09-.94-.55-1.8-1.28-2.4-.73-.6-1.67-.87-2.61-.76-.15.02-15.31 1.67-29.74-3.7-17.34-6.45-33.53 10.3-34.21 11.01a3.498 3.498 0 00-.28 4.49l19.83 26.91c-.92-.29-1.94-.21-2.84.32-.52.31-12.78 7.66-23.54 21.07-14.45 18.01-19.12 38.29-13.51 58.65l.03.11c.23.74 5.85 18.24 24.46 26.39 4.92 2.16 13.61 3.21 26.78 3.21 5.98 0 12.88-.22 20.77-.65 34.51-1.89 39.69-32.83 40.04-35.26 3.31-18.86-2.32-37.58-16.28-54.14zm-43.69-55.55c11.31 4.21 22.79 4.55 28.77 4.37-1.6 7.63-8.42 21.37-13.55 30.4l-20.03-.66-19.75-26.8c4.34-3.71 14.62-11.01 24.56-7.31zm53.07 108.52l-.03.19c-.14 1.13-3.72 27.61-33.49 29.24-23.48 1.29-38.41.62-44.35-1.99-15.23-6.67-20.3-21.19-20.58-22.02-5.02-18.27-.94-35.83 12.11-52.17 9.96-12.47 21.67-19.53 21.78-19.59a3.474 3.474 0 001.7-3.03l.35.47c.64.86 1.63 1.39 2.7 1.42l23.82.78h.12c.09 0 .17-.01.26-.02.15.13.32.26.5.37.42.27 42.27 25.72 35.11 66.35z"
        className="st0"
      />
      <path
        d="M204.97 213.5l-3.03-1.05c-.88-.29-2.1-.93-2.69-1.46-.58-.58-.82-1.29-.82-1.93 0-.87.41-1.52.88-1.92.82-.76 2.16-1.05 3.5-1.05 2.86 0 5.25 1.52 6.54 2.51l3.85-7.47c-3.91-2.34-6.89-2.86-8.81-2.98v-6.42h-5.84v6.42c-2.8.53-5.37 1.93-7.12 3.85-1.69 1.87-2.92 4.55-2.92 8.23 0 2.63.58 4.79 2.51 6.77.87.93 2.69 2.33 6.42 3.62l3.44 1.17c1.22.41 2.04.76 2.63 1.17.76.58 1.17 1.34 1.17 2.22 0 .93-.41 1.81-1.05 2.45-.87.87-2.22 1.34-3.79 1.34-1.69 0-3.85-.64-5.31-1.34-1.28-.64-2.8-1.81-4.03-2.98l-4.2 7.82c1.98 1.58 3.97 2.63 5.66 3.33 2.16.87 4.32 1.34 6.59 1.4v6.3h5.84v-6.6c2.57-.64 5.31-2.04 7.01-3.79 1.28-1.34 3.27-4.09 3.27-8.81 0-2.63-.64-4.9-2.92-7.12-2.22-2.16-4.68-2.98-6.78-3.68z"
        className="st0"
      />
    </svg>
  );
}

export default IconPrize;
