/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { LINKS } from "@constants";
import { BannerContents } from "../../../components/shared/layout";
import { publishAndRemoveBanner } from "../../../utils";

export const BannerConsorcio = (props) => {
  const { images } = props;
  const publishBannerSortudaoExtendedDate = "2024-06-30 23:59:59";
  const removeBannerSortudaoExtendedDate = "2024-08-31 23:59:59";

  const publishBannerAniversario3AnosDate = "2024-08-07 23:59:59";
  const removeBannerAniversario3AnosDate = "2024-08-23 23:59:59";

  const publishBannerAniversario3AnosExtendedDate = "2024-08-23 23:59:59";
  const removeBannerAniversario3AnosExtendedDate = "2024-08-30 23:59:59";

  const BannerAniversario3Anos = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerAniversario3Anos}
      backgroundImageMobile={images.bannerAniversario3AnosMobile}
      href={LINKS.autoCompraConsorcio}
    />
  );

  const BannerAniversario3AnosProrrogacao = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerAniversario3AnosProrrogacao}
      backgroundImageMobile={images.bannerAniversario3AnosProrrogacaoMobile}
      href={LINKS.autoCompraConsorcio}
    />
  );

  const BannerSortudao = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerSortudao}
      backgroundImageMobile={images.bannerSortudaoMobile}
      href={LINKS.sortudao}
    />
  );

  const BannerLibras = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerLibras}
      backgroundImageMobile={images.bannerLibrasMobile}
    />
  );

  const BannerContratacaoSegura = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerContratacaoSegura}
      backgroundImageMobile={images.bannerContratacaoSeguraMobile}
      href={LINKS.linkContratacaoSegura}
    />
  );

  const BannerCDG = () => (
    <BannerContents.Banner
      backgroundImage={images.bannerCDG}
      backgroundImageMobile={images.bannerCDGMobile}
      href={LINKS.autoCompraConsorcio}
    />
  );

  return (
    <BannerContents {...props}>
      {publishAndRemoveBanner(
        publishBannerAniversario3AnosDate,
        removeBannerAniversario3AnosDate
      ) && BannerAniversario3Anos()}

      {publishAndRemoveBanner(
        publishBannerAniversario3AnosExtendedDate,
        removeBannerAniversario3AnosExtendedDate
      ) && BannerAniversario3AnosProrrogacao()}

      {publishAndRemoveBanner(
        publishBannerSortudaoExtendedDate,
        removeBannerSortudaoExtendedDate
      ) && BannerSortudao()}

      {BannerLibras()}

      {BannerCDG()}

      {BannerContratacaoSegura()}
    </BannerContents>
  );
};

BannerConsorcio.defaultProps = { images: {} };

BannerConsorcio.propTypes = {
  images: PropTypes.objectOf(Object),
};

export default BannerConsorcio;
