const CONTRACTS = {
  "contrato-imobiliario": [
    {
      version: 1,
      groups: [
        "10001",
        "10002",
        "10003",
        "10004",
        "10005",
        "10006",
        "10007",
        "10008",
        "10009",
        "10010",
        "10011",
        "10012",
        "10013",
        "10014",
        "10015",
        "10016",
        "10017",
        "10018",
        "10019",
        "10020",
        "10021",
        "10022",
        "10023",
        "10024",
        "10025",
        "10026",
        "10027",
        "10028",
        "10029",
      ],
      link:
        "https://static.caixaconsorcio.com.br/AreaAberta/Consorcio/ContratoDeAdesao/Imobiliario/Contrato_Imobiliario_8-2021.pdf",
    },
    {
      version: 2,
      groups: ["10030", "10031"],
      link:
        "https://static.caixaconsorcio.com.br/AreaAberta/Consorcio/ContratoDeAdesao/Imobiliario/Contrato_Imobiliario_4-2023.pdf",
    },
    {
      version: 3,
      groups: ["10032", "10033", "10034"],
      link:
        "https://static.caixaconsorcio.com.br/AreaAberta/Consorcio/ContratoDeAdesao/Imobiliario/CDG_Contrato_Imobiliario_7-2023.pdf",
    },
    {
      version: 4,
      groups: ["10035", "10036", "10037", "10038", "10039", "10040"],
      link:
        "https://static.caixaconsorcio.com.br/AreaAberta/Consorcio/ContratoDeAdesao/Imobiliario/Contrato_Imobiliario_versao_4.pdf",
    },
  ],
  "contrato-veiculos": [
    {
      version: 1,
      groups: [
        "30001",
        "30002",
        "30003",
        "30004",
        "30005",
        "30006",
        "30007",
        "30008",
        "30009",
        "30010",
        "30011",
        "30012",
      ],
      link:
        "https://static.caixaconsorcio.com.br/AreaAberta/Consorcio/ContratoDeAdesao/Veiculos/Contrato_Veiculos_8-2021.pdf",
    },
    {
      version: 2,
      groups: [
        "30013",
        "30014",
        "30015",
        "30016",
        "30017",
        "30018",
        "30019",
        "30020",
        "30021",
        "30022",
        "30023",
        "30024",
        "30025",
        "30026",
        "30027",
        "30028",
        "30029",
        "30030",
        "30031",
        "30032",
        "30033",
        "30034",
        "30035",
      ],
      link:
        "https://static.caixaconsorcio.com.br/AreaAberta/Consorcio/ContratoDeAdesao/Veiculos/Contrato_Veiculos_3-2022.pdf",
    },
    {
      version: 3,
      groups: ["30036", "30037", "30038", "30039", "30040"],
      link:
        "https://static.caixaconsorcio.com.br/AreaAberta/Consorcio/ContratoDeAdesao/Veiculos/Contrato_Veiculos_4-2023.pdf",
    },
    {
      version: 4,
      groups: [
        "30041",
        "30042",
        "30043",
        "30044",
        "30045",
        "30046",
        "30047",
        "30048",
        "30049",
        "30050",
        "30051",
        "30052",
        "30053",
        "30054",
        "30055",
        "40001",
        "40002",
        "40003",
        "40004",
        "40005",
        "40006",
        "40007",
        "40008",
        "40009",
        "40010",
        "40011",
        "40012",
        "40013",
      ],
      link:
        "https://static.caixaconsorcio.com.br/AreaAberta/Consorcio/ContratoDeAdesao/Veiculos/CDG_Contrato_Ve%C3%ADculos_7-2023.pdf",
    },
  ],
};

function getContractLink(contractType, group) {
  const contract = CONTRACTS[contractType];

  if (!contract) {
    return "error";
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < contract.length; i++) {
    if (contract[i].groups.includes(group)) {
      return contract[i].link;
    }
  }

  return "error"; // Retorna null se o grupo não for encontrado
}

export default getContractLink;
